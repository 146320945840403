import Head from 'next/head';
import useConfig from '../hooks/useConfig';

const GlobalMeta = () => {
  const { openGraph, styles } = useConfig();

  return (
    <Head>
      {/* General */}
      <meta name="theme-color" content={styles.themeColor} />

      {/* Open graph */}
      <meta key="og:description" property="og:description" content={openGraph.description} />
      <meta key="og:image" property="og:image" content={openGraph.image} />
      <meta key="og:site_name" property="og:site_name" content={openGraph.siteName} />
      <meta key="og:title" property="og:title" content={openGraph.title} />
      <meta key="og:type" property="og:type" content="Website" />
      <meta key="og:url" property="og:url" content={openGraph.url} />
    </Head>
  );
};

export default GlobalMeta;
