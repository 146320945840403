import PropTypes from 'prop-types';

const FooterSection = ({ children, title }) => (
  <div className="flex-1 mb-6">
    <div className="text-xl mb-2">{title}</div>
    {children}
  </div>
);

FooterSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default FooterSection;
