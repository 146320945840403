import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const SearchInput = ({ className, onChange, onClear, onEnter, onKeyPress, placeholder, value }) => {
  const inputRef = useRef();
  const handleKeyPress = (e) => {
    const { key } = e;
    if (key === 'Enter') {
      onEnter(e.target.value);
    }

    onKeyPress(e);
  };

  return (
    <Paper className={`${className} flex`}>
      <InputBase
        ref={inputRef}
        placeholder={placeholder}
        className="pl-4 flex-1"
        value={value || ''}
        onChange={({ target }) => onChange(target.value)}
        onKeyPress={handleKeyPress}
      />
      {Boolean(value) && (
        <div>
          <IconButton aria-label="search" onClick={onClear}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <div className="border-l border-solid">
        <IconButton aria-label="search">
          <SearchIcon />
        </IconButton>
      </div>
    </Paper>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onEnter: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

SearchInput.defaultProps = {
  className: '',
  onChange: () => {},
  onClear: () => {},
  onEnter: () => {},
  onKeyPress: () => {},
  placeholder: '',
  value: null,
};

export default SearchInput;
