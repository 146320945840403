import useConfig from '../hooks/useConfig';
import FooterSection from '../ui/FooterSection';
import IconLink from '../ui/IconLink';

const Footer = () => {
  const { contactLinks, copies, legalLinks } = useConfig();

  const segments = copies.aboutUs.split('\n');
  const aboutUsSegments = segments
    .map((text, index) => [
      <span key={index}>{text}</span>,
      index !== segments.length - 1 && <br key={`${index}-p`} />,
    ])
    .flat()
    .filter(Boolean);

  return (
    <footer className="bg-footer text-footer-text p-2 md:p-6">
      <div className="sm:flex border- mb-2 pb-2 border-b w-full max-w-screen-lg mx-auto">
        {contactLinks.length > 0 && (
          <FooterSection title="Contactanos">
            <ul>
              {contactLinks.map(({ display, href }) => (
                <li key={href} className="mb-2">
                  <IconLink href={href} text={display} />
                </li>
              ))}
            </ul>
          </FooterSection>
        )}
        {legalLinks.length > 0 && (
          <FooterSection title="Legal">
            <ul>
              {legalLinks.map(({ display, href }) => (
                <li key={href} className="mb-2">
                  <IconLink href={href} text={display} />
                </li>
              ))}
            </ul>
          </FooterSection>
        )}
        <FooterSection title="Acerca de nosotros">{aboutUsSegments}</FooterSection>
      </div>
      <div className="text-right text-sm w-full max-w-screen-lg mx-auto">
        {'Powered by '}
        <a href="https://intelimotor.com/" target="_blank" rel="noreferrer" className="underline">
          Intelimotor
        </a>
      </div>
    </footer>
  );
};

export default Footer;
