import {
  IconButton,
  useMediaQuery,
  useTheme,
  Link,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import useConfig from '../hooks/useConfig';

const NavigationLinks = () => {
  const { navigationLinks } = useConfig();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState(null);

  const visibleItems = isMobile ? 1 : 3;
  const remainingItems = navigationLinks.length - visibleItems;

  return (
    <>
      {navigationLinks.slice(0, visibleItems).map((link) => (
        <Link key={link.href} href={link.href} className="underline px-2 text-navLink">
          {link.text}
        </Link>
      ))}
      {remainingItems > 0 && (
        <IconButton className="ml-2" onClick={(e) => setAnchorEl(e.currentTarget)}>
          <ExpandMoreIcon />
        </IconButton>
      )}
      <Menu
        id="navigation-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {navigationLinks.slice(remainingItems * -1).map((link) => (
          <Link href={link.href} key={link.href}>
            <MenuItem>
              <ListItemIcon className="min-w-0 mr-2">
                <Icon>{link.icon}</Icon>
              </ListItemIcon>
              <ListItemText className="text-navLink" primary={link.text} />
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </>
  );
};

export default NavigationLinks;
