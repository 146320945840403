import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Footer from '../components/Footer';
import SearchHeader from '../components/SearchHeader';
import GlobalMeta from '../components/GlobalMeta';
import useConfig from '../hooks/useConfig';

const SearchLayout = ({ children }) => {
  const config = useConfig();
  return (
    <div className="flex min-h-full flex-col bg-background">
      <GlobalMeta />
      <Head>
        <title>{config.copies.title}</title>
      </Head>
      <SearchHeader />
      <main className="my-6 flex-grow max-w-screen-lg mx-auto w-full">{children}</main>
      <Footer />
    </div>
  );
};

SearchLayout.propTypes = {
  children: PropTypes.node,
};

SearchLayout.defaultProps = {
  children: null,
};

export default SearchLayout;
