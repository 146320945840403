import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSearchFiltersParams from '../hooks/useSearchFiltersParams';
import SearchInput from '../ui/SearchInput';

const SearchVehiclesInput = () => {
  const { t } = useTranslation('search');
  const { params, updateParams } = useSearchFiltersParams();
  const [value, setValue] = useState(params.search);

  useEffect(() => {
    setValue(params.search);
  }, [params.search]);

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const onEnter = (newValue) => {
    updateParams({
      search: newValue,
      page: null,
    });
  };

  const onClear = () => {
    setValue('');
    updateParams({
      search: '',
    });
  };

  return (
    <SearchInput
      className="w-full"
      placeholder={t('Search Vehicles')}
      value={value}
      onChange={onChange}
      onClear={onClear}
      onEnter={onEnter}
    />
  );
};

export default SearchVehiclesInput;
