import { useRouter } from 'next/router';

/**
 * @typedef { Object } Params
 * @property { string[] } bodyTypes
 * @property { string[] } brands
 * @property { string[] } colors
 * @property { string[] } locations
 * @property { number? } maxEngineUsage
 * @property { number? } maxKms
 * @property { number? } maxPrice
 * @property { number? } minEngineUsage
 * @property { number? } minKms
 * @property { number? } minPrice
 * @property { string[] } models
 * @property { number } page
 * @property { string[] } sellers
 * @property { string } search
 * @property { 'AscYear' | 'DesYear' | 'Default' } sort
 * @property { string[] } states
 * @property { string[] } transmissions
 * @property { string[] } years
 * @property { string[] } vehicles
 * @property { string[] } fifthWheels
 * @property { string[] } rearAxles
 * @property { string[] } cabs
 * @property { string[] } tractions
 * @property { string[] } motors
 * @property { string[] } classes
 * @property { string[] } fuels
 */

/**
 * @param { Params } params
 */
function filterParams(params) {
  /**
   * @type { Record<keyof Params, string> }
   */
  const serialized = {};

  /**
   * @type { Partial<Params> }
   */
  const filtered = {};

  if (params.bodyTypes?.length) {
    serialized.bodyTypes = params.bodyTypes.join('–');
    filtered.bodyTypes = params.bodyTypes;
  }

  if (params.brands?.length) {
    serialized.brands = params.brands.join('–');
    filtered.brands = params.brands;
  }

  if (params.colors?.length) {
    serialized.colors = params.colors.join('–');
    filtered.colors = params.colors;
  }

  if (params.locations?.length) {
    serialized.locations = params.locations.join('–');
    filtered.locations = params.locations;
  }

  if (typeof params.minKms === 'number' && typeof params.maxKms === 'number') {
    serialized.kms = `${params.minKms}-${params.maxKms}`;
    filtered.maxKms = params.maxKms;
    filtered.minKms = params.minKms;
  }

  if (typeof params.minPrice === 'number' && typeof params.maxPrice === 'number') {
    serialized.prices = `${params.minPrice}-${params.maxPrice}`;
    filtered.minPrice = params.minPrice;
    filtered.maxPrice = params.maxPrice;
  }

  if (typeof params.minEngineUsage === 'number' && typeof params.maxEngineUsage === 'number') {
    serialized.engineUsage = `${params.minEngineUsage}-${params.maxEngineUsage}`;
    filtered.minEngineUsage = params.minEngineUsage;
    filtered.maxEngineUsage = params.maxEngineUsage;
  }

  if (params.models?.length) {
    serialized.models = params.models.join('–');
    filtered.models = params.models;
  }

  if (params.page > 1) {
    serialized.page = params.page;
    filtered.page = params.page;
  }

  if (params.search?.trim()) {
    serialized.search = params.search.trim();
    filtered.search = params.search;
  }

  if (params.sort && params.sort !== 'Default') {
    serialized.sort = params.sort;
    filtered.sort = params.sort;
  }

  if (params.states?.length) {
    serialized.states = params.states.join('–');
    filtered.states = params.states;
  }

  if (params.transmissions?.length) {
    serialized.transmissions = params.transmissions.join('–');
    filtered.transmissions = params.transmissions;
  }

  if (params.years?.length) {
    serialized.years = params.years.join('–');
    filtered.years = params.years;
  }

  if (params.bodyTypes?.length) {
    serialized.bodyTypes = params.bodyTypes.join('–');
    filtered.bodyTypes = params.bodyTypes;
  }

  if (params.vehicles?.length) {
    serialized.vehicles = params.vehicles.join('–');
    filtered.vehicles = params.vehicles;
  }

  if (params.fifthWheels?.length) {
    serialized.fifthWheels = params.fifthWheels.join('–');
    filtered.fifthWheels = params.fifthWheels;
  }

  if (params.rearAxles?.length) {
    serialized.rearAxles = params.rearAxles.join('–');
    filtered.rearAxles = params.rearAxles;
  }

  if (params.cabs?.length) {
    serialized.cabs = params.cabs.join('–');
    filtered.cabs = params.cabs;
  }

  if (params.tractions?.length) {
    serialized.tractions = params.tractions.join('–');
    filtered.tractions = params.tractions;
  }

  if (params.motors?.length) {
    serialized.motors = params.motors.join('–');
    filtered.motors = params.motors;
  }

  if (params.classes?.length) {
    serialized.classes = params.classes.join('–');
    filtered.classes = params.classes;
  }

  if (params.fuels?.length) {
    serialized.fuels = params.fuels.join('–');
    filtered.fuels = params.fuels;
  }
  
  if (params.sellers?.length) {
    serialized.sellers = params.sellers.join('–');
    filtered.sellers = params.sellers;
  }

  return { filtered, serialized };
}

/**
 * @param { { [key in keyof Params]: string } } query
 * @returns { Params }
 */
function deserializeParams(query) {
  const bodyTypes = query.bodyTypes?.split('–') || [];

  const brands = query.brands?.split('–') || [];

  const colors = query.colors?.split('–') || [];

  const locations = query.locations?.split('–') || [];

  const [minPriceRaw, maxPriceRaw] = (query?.prices || '').split('-');
  const maxPrice = query.prices ? Number(maxPriceRaw) : null;
  const minPrice = query.prices ? Number(minPriceRaw) : null;

  const [minKmsRaw, maxKmsRaw] = (query?.kms || '').split('-');
  const maxKms = query.kms ? Number(maxKmsRaw) : null;
  const minKms = query.kms ? Number(minKmsRaw) : null;

  const [minEngineUsageRaw, maxEngineUsageRaw] = (query?.engineUsage || '').split('-');
  const minEngineUsage = query.engineUsage ? Number(minEngineUsageRaw) : null;
  const maxEngineUsage = query.engineUsage ? Number(maxEngineUsageRaw) : null;

  const models = query.models?.split('–') || [];

  const page = Number(query.page || 1);

  const sellers = query.sellers?.split('–') || [];

  const [search = ''] = query.search || [];

  const sort = query.sort || 'Default';

  const states = query.states?.split('–') || [];

  const transmissions = query.transmissions?.split('–') || [];

  const years = query.years?.split('–') || [];

  const vehicles = query.vehicles?.split('–') || [];

  const fifthWheels = query.fifthWheels?.split('–') || [];

  const rearAxles = query.rearAxles?.split('–') || [];

  const cabs = query.cabs?.split('–') || [];

  const tractions = query.tractions?.split('–') || [];

  const motors = query.motors?.split('–') || [];

  const classes = query.classes?.split('–') || [];

  const fuels = query.fuels?.split('–') || [];

  return {
    bodyTypes,
    brands,
    colors,
    locations,
    maxKms,
    maxPrice,
    minKms,
    minPrice,
    models,
    page,
    sellers,
    search,
    sort,
    states,
    transmissions,
    years,
    vehicles,
    fifthWheels,
    rearAxles,
    cabs,
    tractions,
    motors,
    classes,
    fuels,
    minEngineUsage,
    maxEngineUsage,
  };
}

export default function useSearchFiltersParams() {
  const router = useRouter();
  const params = deserializeParams(router.query);
  const selectedFilters = filterParams({ ...params }).filtered;
  delete selectedFilters.page;
  delete selectedFilters.sort;

  /** @type { (params: Params) => void} */
  const updateParams = (newParams) => {
    const { serialized } = filterParams({ ...params, ...newParams });
    router.push({
      pathname: '/buscar/[[...search]]',
      query: serialized,
    });
  };

  const removeParams = () => {
    router.push('/buscar/[[...search]]');
  };

  return {
    params,
    removeParams,
    selectedFilters,
    updateParams,
  };
}
