// import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Icon from '@material-ui/core/Icon';
import useConfig from '../hooks/useConfig';

const NavigationDrawer = () => {
  const { navigationLinks, drawerLogo } = useConfig();
  const [visible, setVisible] = useState(false);

  if (navigationLinks.length === 0) return null;

  return (
    <>
      <IconButton className="outline-none" onClick={() => setVisible(true)}>
        <MenuIcon className="text-header-text" />
      </IconButton>

      <SwipeableDrawer
        open={visible}
        onClose={() => setVisible(false)}
        onOpen={() => setVisible(true)}
        anchor="right"
      >
        <div className="w-full h-full bg-drawer text-drawer-text">
          <List>
            <img src={drawerLogo} alt="" className="h-14 px-4 p-2 mb-2" />
            <Divider />
            {navigationLinks.map((link) => (
              <Link href={link.href} key={link.href}>
                <ListItem>
                  <Icon>{link.icon}</Icon>
                  <ListItemText primary={link.text} />
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default NavigationDrawer;
