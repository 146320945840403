import { useQuery } from '@apollo/client';
import getSellersQuery from '../queries/getSellers';

export default function useGetSellers() {
  const { data, error, loading } = useQuery(getSellersQuery);

  return {
    loading,
    error,
    sellers: data?.getSellers,
  };
}
