import PropTypes from 'prop-types';
import LinkIcon from '@material-ui/icons/Link';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/MailOutline';

function getIcon(href) {
  if (href.startsWith('tel:')) {
    return <PhoneIcon />;
  }
  if (href.startsWith('mailto:')) {
    return <MailIcon />;
  }

  return <LinkIcon />;
}

const IconLink = ({ className, href, text }) => (
  <a href={href} className={`underline flex ${className}`}>
    {getIcon(href)}
    <span className="ml-1">{text}</span>
  </a>
);

IconLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

IconLink.defaultProps = {
  className: '',
};

export default IconLink;
