import Link from 'next/link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import SearchVehiclesInput from './SearchVehiclesInput';
import useConfig from '../hooks/useConfig';
import NavigationDrawer from './NavigationDrawer';
import NavigationLinks from './NavigationLinks';

const SearchHeader = () => {
  const { copies, logo, logoLink, mobileLogo, secondLogo } = useConfig();

  return (
    <AppBar id="header" position="static" className="position-relative bg-header text-header-text">
      <Toolbar className="-md:justify-center max-w-screen-lg mx-auto w-full">
        <Hidden smDown>
          <Link href={logoLink || '/buscar'}>
            <img src={logo} alt={copies.title} className="h-14 p-2 cursor-pointer" />
          </Link>
        </Hidden>
        <Hidden mdUp>
          <Link href={logoLink}>
            <img src={mobileLogo || logo} alt={copies.title} className="h-14 p-2 cursor-pointer" />
          </Link>
        </Hidden>
        <div className="md:ml-8 w-full md:w-1/2 lg:w-1/3">
          <SearchVehiclesInput />
        </div>
        {secondLogo && (
          <img src={secondLogo} alt={copies.title} className="h-14 p-2 cursor-pointer" />
        )}
        {/* this div align to right */}
        <div className="ml-auto" />
        <Hidden smDown>
          <NavigationLinks />
        </Hidden>
        <Hidden mdUp>
          <NavigationDrawer />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default SearchHeader;
