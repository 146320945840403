import { gql } from '@apollo/client';

const getSellersQuery = gql`
  query {
    getSellers {
      id
    }
  }
`;

export default getSellersQuery;
